import { MAX_USER_AGE, MIN_USER_AGE } from "shared/constants";
import { monthShort } from "shared/constants/date";
import {
  HOURS_PER_DAY,
  MILISECOND_PER_SECOND,
  SECONDS_PER_MINUTE,
} from "shared/constants/holidayEvent";

/**
 * Get days from 1 to 31
 * @return {object}
 */
export function getDate() {
  const dates = {};

  for (let i = 1; i <= 31; i++) {
    dates[i] = i;
  }

  return dates;
}

/**
 * Get list of months
 * @return {object}
 */
export function getMonths() {
  const monthsLabel = monthShort;
  const months = {};

  for (let i = 0; i < 12; i++) {
    months[i + 1] = monthsLabel[i];
  }

  return months;
}

/**
 * Get object of years
 * @return {object}
 */
export function getYears() {
  const year = parseInt(new Date().getFullYear()) - MIN_USER_AGE;
  const startFrom =
    parseInt(year) - (parseInt(MAX_USER_AGE) - parseInt(MIN_USER_AGE));
  const years = [];

  for (let i = year; i > startFrom; i--) {
    years[i] = i;
  }

  const yearsArray = years.reverse();
  return Object.assign({}, yearsArray);
}

/**
 * Get year's key
 * @param {string|number} value year
 * @return {null|number}
 */
export function getYearKey(value) {
  const years = getYears();

  for (let i = 0; i < years.length; i++) {
    if (years[i] === value) {
      return i;
    }
  }

  return null;
}

/**
 * Get year by key
 * @param {string|number} key of year
 * @return {number}
 */
export function getYearByKey(key) {
  const years = getYears();
  return years[key];
}

/**
 * Get birthday from registration data
 * @param {string} birthday of user
 * @return {object}
 */
export function getBirthdayFromRegDate(birthday) {
  const date = new Date(birthday);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return {
    year: year,
    month: month,
    day: day,
  };
}

/**
 * Validate birthday date
 * @param {string|number} dayValue
 * @param {string|number} monthValue
 * @param {string|number} yearValue
 * @return {boolean}
 */
export function validateBirthDate(dayValue, monthValue, yearValue) {
  if (
    typeof dayValue === "undefined" ||
    typeof monthValue === "undefined" ||
    typeof yearValue === "undefined"
  ) {
    return false;
  }

  const today = new Date();
  const age = today.getFullYear() - parseInt(yearValue);

  return !(age < MIN_USER_AGE || age > MAX_USER_AGE);
}

/**
 * Get birthday date string
 * @param {string|number} day
 * @param {string|number} month
 * @param {string|number} year
 * @return {string}
 */
export function getBirthdayValue(day, month, year) {
  return (
    year +
    "-" +
    (month >= 10 ? month : "0" + month) +
    "-" +
    (day >= 10 ? day : "0" + day)
  );
}

/**
 * Get browser timezone
 * @return {string}
 */
export function getTimeZone() {
  const offset = new Date().getTimezoneOffset();
  let hrs = parseInt(Math.abs(offset / 60));
  let min = Math.abs(offset % 60);

  hrs = hrs < 10 ? "0" + hrs : hrs;
  min = min < 10 ? "0" + min : min;

  if (offset < 0) {
    return "+" + hrs + ":" + min;
  } else {
    return "-" + hrs + ":" + min;
  }
}

/**
 * Get browser language
 * @return {string}
 */
export function getClientLanguage() {
  const userLang = navigator.language || navigator.userLanguage;

  if (!userLang) {
    return "";
  }

  return userLang;
}

/**
 * Get date in a specific format
 * @param {number} year Year
 * @param {number} month Month
 * @param {number} day Day
 * @return {string}
 */
export const getBirthdayInFormat = (year, month, day) => {
  return (
    year +
    "-" +
    (month < 10 ? "0" + month : month) +
    "-" +
    (day < 10 ? "0" + day : day)
  );
};

/**
 *Get date in format
 *@param {string} date
 *@return {string}
 */

export const getDateFormatOrderPresents = (date) => {
  const dateObject = new Date(date);
  const monthString = dateObject.toLocaleString("en-US", {
    month: "short",
  });
  return `${monthString} ${dateObject.getDate()} ${dateObject.getFullYear()}`;
};

/**
 *Get boolean info 24 hours after make presents
 *@param {string} inputDate
 *@return {boolean}
 */
export const checkIfCanceledOrder = (inputDate) => {
  // Assuming inputDate is in the format "YYYY-MM-DD HH:mm:ss"

  const [datePart, timePart] = inputDate.split(" ");
  const [year, month, day] = datePart.split("-");
  const [hours, minutes, seconds] = timePart.split(":");

  // Creating a new Date object with the correct format
  const inputDatetime = new Date(year, month - 1, day, hours, minutes, seconds);

  // Get current UTC time
  const currentUtcTime = new Date().getTime();

  // Calculate target UTC time by adding 24 hours in milliseconds
  const targetUtcTime =
    inputDatetime.getTime() +
    HOURS_PER_DAY *
      SECONDS_PER_MINUTE *
      SECONDS_PER_MINUTE *
      MILISECOND_PER_SECOND;

  return currentUtcTime < targetUtcTime;
};
